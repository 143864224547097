import React, { useEffect, useState } from 'react';
import { Container } from 'components/Layout';
import BrandIdentityList from './components/brandIdentityList';
import FormatsList from './components/formatsList';
import { BRANDIDENTITY, FORMATS } from 'utils/rest';
import Loading from 'components/Loading';
import { IOSSwitch } from 'modules/Contents/components/Inputs/IOSSwitch';
const ConfigFormatsContainer = () => {
  const [loaded, setLoaded] = useState(false);
  const [formatsData, setFormatsData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [rows, setRows] = useState([]);
  const [selectedBrandidentity, setSelectedBrandIdentity] = useState({});
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [search, setSearch] = useState(false);
  const [selectedFormats, setSelectedFormats] = useState([]);

  const loadBrandIdentity = async () => {
    setLoaded(true);
    const {
      findBrandIdentity,
      totalItems
    } = await BRANDIDENTITY.getAllUserBrandIdentity(page, searchValue);

    if (findBrandIdentity) {
      if (findBrandIdentity?.length === 1) setTotalItems(1);
      else setTotalItems(totalItems);

      const tempoArray = findBrandIdentity?.map(elm => ({
        id: elm.groupId,
        organisation: elm.name[0],
        marque: '1080X1080-375X223-etc',
        hasTemplate: elm?.hasTemplate
      }));
      setRows(tempoArray);
      setLoaded(false);
    }
  };
  useEffect(() => {
    if (formatsData.length === 0) {
      return;
    }
    loadBrandIdentity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search, formatsData]);
  const loadFormats = async () => {
    try {
      const data = await FORMATS.getFormats();
      const temp = {};
      data.forEach(item => {
        item.mediaFormat.forEach(mediaFormat => {
          if (!temp[mediaFormat.media]) {
            temp[mediaFormat.media] = [];
          }
          if (
            !temp[mediaFormat.media].some(f => {
              return f.height === item.height && f.width === item.width;
            })
          ) {
            temp[mediaFormat.media].push({
              height: item.height,
              width: item.width
            });
          }
        });
      });
      const formattedPlatforms = Object.keys(temp).map(key => ({
        name: key.charAt(0).toUpperCase() + key.slice(1),
        formats: temp[key]
      }));
      setFormatsData(formattedPlatforms);
    } catch (error) {
      console.error('Failed to load formats:', error);
    }
  };
  useEffect(() => {
    loadFormats();
  }, []);
  const handleToggleChange = (id, value) => {
    setRows(prevRows =>
      prevRows.map(row =>
        row.id === id ? { ...row, hasTemplate: value } : row
      )
    );
    setSelectedFormats([]);
    setShowTable(true);
  };
  return (
    <Container>
      {showTable ? (
        <Loading loading={loaded}>
          <BrandIdentityList
            rows={rows}
            setRows={setRows}
            setShowTable={setShowTable}
            setSelectedBrandIdentity={setSelectedBrandIdentity}
            IOSSwitch={IOSSwitch}
            setPage={setPage}
            page={page}
            totalItems={totalItems}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            setSearch={setSearch}
            search={search}
            selectedBrandidentity={selectedBrandidentity}
            setSelectedFormats={setSelectedFormats}
          />
        </Loading>
      ) : (
        <FormatsList
          formatsData={formatsData}
          setShowTable={setShowTable}
          selectedBrandidentity={selectedBrandidentity}
          handleToggleChange={handleToggleChange}
          IOSSwitch={IOSSwitch}
          selectedFormats={selectedFormats}
          setSelectedFormats={setSelectedFormats}
        />
      )}
    </Container>
  );
};
export default ConfigFormatsContainer;
